import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

const GuestGuard = ({ children }) => {
  const { isAuthenticated , user} = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  if (isAuthenticated) {
    // enqueueSnackbar('Login Successful', {
    //   anchorOrigin: {
    //     vertical: 'top',
    //     horizontal: 'center',
    // },
    //   variant: 'success'
    // })
    return <Redirect to= {user ? user.user_group === 'pexaminer' ?"/app/head/pract_schedules":"/app/admin/home" : "/app/admin/home"}    />;
    
    // {user.user_group === 'owner' ? ("/app/admin/faculty" ) : ("/app/admin/profile" )}
    
    


  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
